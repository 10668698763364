<template>
  <div class="container">
    <div class="center insetCenter">
      <div class="block for-meta-tag">
        <MetaTag
            :tag-data="{
            name:'sdl123',
            id:11
      }"
            :keyword="keyword"
            :show-close-btn="true"
            :float="false"
        />
        <MetaTag
            :tag-data="{
            name:'上传发票',
            id:12
      }"
            :has-cursor-tip="true"
            :type="'primary'"
        >
          <template #icon>
            <svg-icon name='upload' width="14" height="14"/>
          </template>
        </MetaTag>
        <CertificateTag
            :tag-data="{
               name:'上传凭据',
               resolved:resolved1,
               type:TagType.NEED_RESOLVE,
               voucherRequired: true
            }"
            :upload-url="'https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15'"
        ></CertificateTag>
        <CertificateTag
            :tag-data="{name:'收款'}"
            :upload-url="'https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15'"
        ></CertificateTag>
        <div><input v-model="keyword"></div>
      </div>

      <div class="block for-mc">
        <TagComposition
            ref="tagComposition"
            :selectable-tags-data="testData.multi.selectableTagsData"
            :selected-tags-data="testData.multi.tagsData"
        ></TagComposition>
        <div>
          <button @click="switchMode">切换mode</button>
          <button @click="addOneData">增加一个数据</button>
          <button @click="addMultiData">增加多个数据</button>
          <button @click="deleteRandomData">随机删除数据</button>
        </div>
      </div>
      <div class="block">
        <TagComposition
            ref="tagCompositionSingletonMode"
            :selectable-tags-data="testData.singleton.selectableTagsData.filter(td => td.type === TagType.NEED_RESOLVE)"
            :selected-tags-data="testData.singleton.tagsData"
            :singleton-mode="true"
            placeholder=" 搜索待办"
            list-placeholder="请选择或传创建一个待办标签"
            @modeChanged="() => {
              //模式切换 (编辑、只读)
            }"
            @containerTagDataAppended="() => {
              //顶部的展示增加了标签
            }"
            @tagResolved="()=>{
              //待办的标签已完成
            }"
            :setting-menu-mode-type="TagListItemSettingMenuMode.ONLY_NEED_RESOLVE"
            :create-new-tag-data-default-type="TagType.NEED_RESOLVE"
        ></TagComposition>

        <div>
          <button @click="() => $refs.tagCompositionSingletonMode.api.changeMode()">切换mode</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetaTag from "../../components/tag/MetaTag";
import CertificateTag from "../../components/tag/CertificateTag";
import {colorLog,uuidGen} from "../../util";
import TagComposition from "../../components/tag/TagComposition";
import {TagListItemSettingMenuMode, TagType} from "../../components/tag/configure";
export default {
  name: "TestTagEdit",
  components: {TagComposition, CertificateTag, MetaTag},
  data(){
    return {
      TagType,
      keyword:'sd12',
      resolved1:false,
      resolved2:false,
      siwtchBool:false,
      testData:{
        multi:{
          tagsData:[
            {
              type:TagType.NORMAL,
              id:0,
              name:'一采购招标'
            },
            {
              type:TagType.NEED_RESOLVE,
              id:1,
              name:'二上传发票',
              voucherRequired:true,
              resolved:false
            },
            {
              type:TagType.NEED_RESOLVE,
              id:2,
              name:'三收款',
              resolved:false
            },
            {
              type:TagType.NORMAL,
              id:3,
              name:'四咩咩咩咩吗'
            },
            {
              type:TagType.NEED_RESOLVE,
              id:4,
              name:'五收款22222',
              resolved:true
            },
          ],
          selectableTagsData:[
            {
              type:TagType.NORMAL,
              id:0,
              name:'一采购招标',
              order:6
            },
            {
              type:TagType.NEED_RESOLVE,
              id:1,
              name:'二上传发票',
              voucherRequired:true,
              resolved:false,
              order:7
            },
            {
              type:TagType.NEED_RESOLVE,
              id:2,
              name:'三收款',
              resolved:true,
              order:3
            },
            {
              type:TagType.NORMAL,
              id:3,
              name:'四咩咩咩咩吗',
              order:1
            },
            {
              type:TagType.NEED_RESOLVE,
              id:4,
              name:'五收款22222',
              resolved:true,
              order:1
            },
            {
              type:TagType.NORMAL,
              id:5,
              name:'穿山甲,毒鸡汤',
              resolved:true,
              order:2
            },
            {
              type:TagType.NORMAL,
              id:6,
              name:'可爱的羊八鲁',
              order:3
            }
          ]
        },
        singleton:{
          tagsData:[
          ],
          selectableTagsData:[
            {
              type:TagType.NORMAL,
              id:0,
              name:'一采购招标',
              order:6
            },
            {
              type:TagType.NEED_RESOLVE,
              id:1,
              name:'二上传发票',
              voucherRequired:true,
              resolved:false,
              order:7
            },
            {
              type:TagType.NEED_RESOLVE,
              id:2,
              name:'三收款',
              resolved:true,
              order:3
            },
            {
              type:TagType.NORMAL,
              id:3,
              name:'四咩咩咩咩吗',
              order:1
            },
            {
              type:TagType.NEED_RESOLVE,
              id:4,
              name:'五收款22222',
              resolved:true,
              order:1
            },
            {
              type:TagType.NORMAL,
              id:5,
              name:'穿山甲,毒鸡汤',
              resolved:true,
              order:2
            },
            {
              type:TagType.NORMAL,
              id:6,
              name:'可爱的羊八鲁',
              order:3
            }
          ]
        }
      },
      TagListItemSettingMenuMode

    }
  },
  methods:{
    switchMode(){
      this.$refs.tagComposition.api.changeMode();
    },
    addOneData(){
      this.tagsData.push({
        type:TagType.NEED_RESOLVE,
        id:uuidGen(),
        name:'单个数据',
        resolved:false
      })
    },
    addMultiData(){
      const data = Array(Math.ceil(Math.random() * 10)).fill(null).map(() => ({
        type:(Math.random() > .5) ? TagType.NEED_RESOLVE : TagType.NORMAL,
        id:uuidGen(),
        name:'多个数据',
        resolved:false,
      }));
      this.tagsData.push(...data);
    },
    deleteRandomData(){
      Array(Math.ceil(Math.random() * 3)).fill(null).forEach(() => {
        const index = Math.floor(Math.random() * this.tagsData.length);
        this.tagsData.splice(index,1);
      });
    }
  },
  created() {
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  background: white;
}
.center{
  width: 400px;
  height: 600px;
}
.block{
  border: 1px dashed;
  padding: 10px;
  background: var(--near-white);
  background-clip: content-box;
}
.block+.block{
  margin-top: 20px;
}
</style>
